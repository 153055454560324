.blink {
    animation: blink-animation 1s infinite;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: "10px";
    
  }
  
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
 