/* .carousel {
  width: 100%;
  height: auto;
  margin: 0 auto;
 
}

.carousel img {
  width: 100%;
  height: auto;
  margin: auto;
 
}

@media (max-width: 767px) {
  .carousel {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .carousel {
    height: 200px;
  }
}

.legend {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 20px;
  padding: 5px;
  text-align: center;
} */

/* 
.carousel-image {
  height: 200px; 
} */



.carousel-root {
  margin-top: 75px; 
}